import { defineStore } from 'pinia'
import axios, { Axios, AxiosError } from 'axios'
import { toast } from 'vue3-toastify'

interface State {
  email: string|null,
  userId: number|null,
  gToken: string|null,
  wlToken: string|null,
  wallets: string[],
  loading: boolean
}

const defaultState: State = {
  email: null,
  userId: null,
  gToken: null,
  wlToken: null,
  wallets: [],
  loading: false
}

const session = axios.create({
  baseURL: process.env.VUE_APP_LOOTLOCKER
})

let loggedSession: Axios|null = null

export const useLootLocker = defineStore('LootLocker', {
  state: () => defaultState,
  actions: {
    async login (email: string, password: string) {
      if (this.loading) return
      this.loading = true
      try {
        const { gToken, wlToken, userId } = (await session.post('/login', { email, password })).data
        this.gToken = gToken
        this.wlToken = wlToken
        this.userId = userId
        this.email = email
      } catch (e: any) {
        this.handleAxiosError(e)
        return
      }

      loggedSession = axios.create({
        baseURL: process.env.VUE_APP_LOOTLOCKER,
        headers: {
          'x-session-token': this.gToken,
          'wl-token': this.wlToken,
          email: this.email
        }
      })

      this.initStorage()

      this.loading = false
    },
    logout () {
      this.gToken = null
      this.wlToken = null
      this.userId = null
      this.email = null
    },
    async initStorage () {
      if (!loggedSession) return
      try {
        const { wallets } = (await loggedSession.get('/get-wallets')).data
        this.wallets = wallets || []
      } catch (e: any) {
        this.handleAxiosError(e)
      }
    },
    async signup (email: string, password: string) {
      if (this.loading) return
      this.loading = true
      try {
        await session.post('/signup', { email, password })
        toast.success('Account registered successfully!', {
          dangerouslyHTMLString: true,
          position: 'bottom-center'
        })
      } catch (e: any) {
        this.handleAxiosError(e)
      }
      this.loading = false
    },
    async resetPassword (email: string) {
      if (this.loading) return
      this.loading = true
      try {
        await session.post('/reset-password', { email })
        toast.success('Email sent! Check it to change your password!', {
          dangerouslyHTMLString: true,
          position: 'bottom-center'
        })
      } catch (e: any) {
        this.handleAxiosError(e)
        return
      }
      this.loading = false
    },
    async addWallet (wallet: string) {
      if (!loggedSession || this.loading) return
      this.loading = true
      console.log('ADD WALLET!', wallet)
      try {
        await loggedSession.post('/add-wallet', { wallet })
        this.wallets.push(wallet)
        toast.success('Wallet added successfully!', {
          dangerouslyHTMLString: true,
          position: 'bottom-center'
        })
      } catch (e: any) {
        this.handleAxiosError(e)
        return
      }
      this.loading = false
    },
    async removeWallet (wallet: string) {
      if (!loggedSession || this.loading) return
      let i
      if ((i = this.wallets.indexOf(wallet)) !== -1) {
        this.loading = true
        try {
          await loggedSession.post('/remove-wallet', { wallet })
        } catch (e: any) {
          this.handleAxiosError(e)
          return
        }
        this.wallets.splice(i, 1)
        toast.success('Wallet removed successfully!', {
          dangerouslyHTMLString: true,
          position: 'bottom-center'
        })
      }
      this.loading = false
    },
    handleAxiosError (error: AxiosError) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        const msg = (error.response.data as any).msg
        console.log('Error message: ', msg)

        toast.error(msg, {
          dangerouslyHTMLString: true,
          position: 'bottom-center'
        })
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request)
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', error.message)
      }
      this.loading = false
    }
  },
  getters: {
    isLogged (): boolean {
      return !!this.gToken && !!this.wlToken
    }
  }
})
